import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HeaderWrapper, OverlayDashboard } from "../HealthTabV2/styles";
import PropTypes from 'prop-types';
// import Waiting from '../Waiting';
import { MainEventsContainer } from '../MainEvents/styles';
import LaunchSurveyPopUp from '../Experience/LaunchSurveyPopUp';
import { getPermissionStatus } from '../../utils/methods';
import HoverButton from '../common/HoverButton';
import { BuddyTab, BuddyTabContainer } from '../PeopleHomeV2/styles';
import SurveyDashboard from './SurveyDashboard';
import { withRouter } from 'react-router-dom';
import { AdminEventsAddCreateEventButton, /*customSurevyIcon*/ } from '../../utils/icons';
import CustomSurveyPopUp from './SurveyLibrary/CustomSurveyPopUp';
import { Dropdown } from 'react-bootstrap';
import { SurveyActionsDropdown } from './style';
import SurveyLibrary from './SurveyLibrary';
import CompletedSurveys from './CompletedSurveys';

class NewAdminSurvey extends Component {
  constructor() {
    super();
    this.state = {
      header: '',
      indicatorStatus: 0,
      percentage: 0,
      surveyId: 0,
      launchSurvey: false,
      printEnrolledUsers: false,
      selectedTabId: 1,
      printCSV: false,
      isloading: false,
      activeTab:1,
      isCustomSurvey:false
    }
  }
  componentDidMount() {

    if(this.props.history.location.pathname === "/company/surveys"){
      this.setState({activeTab:1})
    }else if(this.props.history.location.pathname === "/company/surveys/completed"){
      this.setState({activeTab:2})
    } else if(this.props.history.location.pathname === "/company/surveys/library"){
      this.setState({activeTab:3})
    }

  }

  showCreateSurvey = () => {
    const { history } = this.props;
    history.push('/company/surveys/show-all');
  };
  launchCultureSurvey = () => {
    this.setState((prevState) => ({
      launchSurvey: !prevState.launchSurvey
    }));
  }

  updatePrintState = () => {
    this.setState((prevState) => ({ printEnrolledUsers: !prevState.printEnrolledUsers }))
  };

  downloadEnrolledList = (bool) => {
    this.setState({
      printCSV: bool,
      isloading: bool
    })
  };

  setActiveTab = (tabId) => {
    window.console.log("tabId",tabId)
    this.setState({activeTab:tabId})

  }
   

  render() {
    const { header, launchSurvey, surveyId, activeTab, isCustomSurvey } = this.state;
    const { surveyReportDetails, /*isLoading,*/  history, userPermissions,role } = this.props;

    const createCustomSurvey = getPermissionStatus("Create custom survey", userPermissions);
    return (
      <OverlayDashboard>
        <MainEventsContainer marginTop="1" >
          <HeaderWrapper padding={1} style={{padding:'0px'}}>
            <BuddyTabContainer marginBottom="25px" style={{display:"flex",justifyContent:"space-between"}}>
              <div style={{display:"flex"}}>
                <BuddyTab
                  width
                  padding
                  active={activeTab === 1}
                  onClick={() =>{history.push("/company/surveys");this.setState({activeTab:1})}}
                >
                  {"Surveys"}
                </BuddyTab>
                <BuddyTab
                  width
                  padding
                  active={activeTab === 2}
                  onClick={()=>{history.push("/company/surveys/completed");this.setState({activeTab:2})}}
                >
                  {"Completed"}
                </BuddyTab>
                <BuddyTab
                  width
                  padding
                  active={activeTab === 3}
                  onClick={()=>{history.push("/company/surveys/library");this.setState({activeTab:3})}}
                >
                  {"Library"}
                </BuddyTab>
              </div>

              <div>
                <SurveyActionsDropdown className="create-events" style={{padding: "0px"}}>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <HoverButton svgPath={AdminEventsAddCreateEventButton()} activeColor={"#007AB1"} fillOpacity={1} title={"Create Survey"} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => history.push(`/company/surveys/launch-custom-survey`)} >
                        Create survey from scratch
                      </Dropdown.Item>
                      <><hr/>
                        <Dropdown.Item onClick={() =>{history.push(`/company/surveys/library`);this.setState({activeTab:3})}} >
                        Create survey from library
                        </Dropdown.Item>
                        <hr/>
                        <Dropdown.Item onClick={() => history.push(`/company/surveys/create-survey-library`)}>Create survey library</Dropdown.Item>
                      </>
                    </Dropdown.Menu>
                  </Dropdown>
                </SurveyActionsDropdown>
              </div>

            </BuddyTabContainer>
            {activeTab === 1 && (<SurveyDashboard  surveyId={surveyId} surveyReportDetails={surveyReportDetails} selectedYearInfo={this.props.selectedYearInfo} header={header} setHeader={this.setHeader} createCustomSurveyPermission={createCustomSurvey} companyInfo={this.props.companyInfo} role={role} setActiveTab={this.setActiveTab}/>)}
            {isCustomSurvey && <CustomSurveyPopUp showModal={isCustomSurvey} onClose={()=>this.setState({isCustomSurvey:false})} setActiveTab={this.setActiveTab}/>}

            {activeTab === 2 && (<CompletedSurveys role={role} companyInfo={this.props.companyInfo}/>)}

            {activeTab === 3 && (<SurveyLibrary  role={role} companyInfo={this.props.companyInfo}/>)}

            {launchSurvey && <LaunchSurveyPopUp history={history} surveyReportDetails={surveyReportDetails} show={launchSurvey} onHide={this.launchCultureSurvey} companyInfo={this.props.companyInfo} />}
          </HeaderWrapper>
        </MainEventsContainer>
      </OverlayDashboard>
    );
  }
}

NewAdminSurvey.propTypes = {
  companyInfo: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  getSurveyOverviewDetails: PropTypes.func,
  getSurveyReports: PropTypes.func,
  surveyReportDetails: PropTypes.array,
  isLoading: PropTypes.bool,
  getTopSurveyLocations: PropTypes.func,
  getTopSurveyDepartments: PropTypes.func,
  isAdmin: PropTypes.string,
  userPermissions: PropTypes.array,
  getSurveyReportData: PropTypes.func,
  enrolledUsers: PropTypes.array,
  csvButton: PropTypes.bool,
  fitnessUsers: PropTypes.array,
  getSurveyLibraries: PropTypes.func.isRequired,
  surveys:PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  surveyReportDetails: state.surveys.surveyReportDetails,
  isLoading: state.surveys.isLoading,
  userPermissions: state.profileData.userPermissions,
  surveys: state.surveys.surveyLibraries,
})


export default withRouter(connect(mapStateToProps, null)(NewAdminSurvey));
